@import "shared/utils/colors.scss";

.tabsContainer {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabsContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabsContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.activeTab {
  height: 40px;
  width: 80px;
  padding-bottom: 9px;
  position: relative;
  top: -7px;
  min-height: 28px;
}

.tabsContainer::before {
  position: absolute;
  bottom: 7.5px;
  left: 0;
  width: 100%;
  height: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #e4e4e4;
  content: "";
}

@media screen and (max-width: 455px) {
  .activeTab {
    height: 18px;
    padding-bottom: 4px;
    position: relative;
    top: -0.25px;
  }

  .tabsContainer::before {
    bottom: 0.5px;
  }
}

@media screen and (min-width: 455px) and (max-width: 576px) {
  .activeTab {
    height: 20px;
    padding-bottom: 6px;
    position: relative;
    top: -2.5px;
  }

  .tabsContainer::before {
    bottom: 3px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .tabsContainer::before {
    bottom: 7.5px;
  }
}
