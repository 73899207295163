@import "shared/utils/colors.scss";
.quill {
  display: flex;
  flex-direction: column-reverse;
}

.ql-toolbar.ql-snow {
  border: 0px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ql-container.ql-snow {
  border: 1px solid $BORDER !important;
  border-radius: 24px;
  background-color: #f9fafb;
  height: 241px;
  margin-bottom: 20px;
}
.ql-editor {
  font-family: HNRegular;
  color: $DARK_GREY;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  height: 26px !important;
  width: 26px !important;
}

.ql-snow .ql-stroke {
  stroke: #98a2b3;
}

.ql-snow .ql-fill {
  fill: #98a2b3;
}

.error {
  color: #ea5b5b;
  font-size: 11px;
  text-align: left;
  position: absolute;
  z-index: 1;
  bottom: 40px;
}

.word-label {
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 0px;
  font-size: 14px;
  font-family: InterRegular;
  color: $GREY;
}
