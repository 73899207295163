@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
.contestCardContainer {
  background-color: white;
}

.btnContainer {
  background-color: transparent;
  border: 1px solid $DARK_GREY;
}

.readMoreMobile {
  font-family: HNRegular;
  font-size: 14px;
  color: #868e96;
  text-align: left;
}

.readMoreText {
  font-family: HNRegular;
  font-size: 16px;
  color: #868e96;
  text-align: left;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.submitStoryContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 140px;
  background-color: $SECONDARY;
  border-radius: 24px;
  &:hover {
    cursor: pointer;
  }
}

.submitLabel {
  font-family: HNRegular;
  font-size: 14px;
  text-align: left;
  color: black;
  &:hover {
    cursor: pointer;
  }
}

.appertureIcon {
  height: 18px;
  width: 18px;
}

.appertureIcon path {
  stroke: black;
}

.artStyle {
  min-height: 285px;
  max-height: 285px;
  min-width: 100%;
  object-fit: fill;
}

.iconUpArrow {
  min-height: 24px;
  min-width: 24px;
  margin-top: 3px;
}

.iconUpArrow path {
  stroke: $DARK_GREY;
}

.readMoreText {
  cursor: pointer;
}

.storyLabel {
  font-family: HNRegular;
  font-size: 22px;
  color: $GREY2;
  &:hover {
    cursor: pointer;
  }
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.storyLabelSave {
  font-family: ALBold !important;
  color: $DARK_GREY !important;
}

.appertureIcon {
  height: 18px;
  width: 18px;
}
.descContainer {
  max-height: 100px;
  min-height: 100px;
}
.descStyle {
  font-family: HNRegular;
  font-size: 16px;
  color: $GREY2;
  text-align: left;
  @include elipsis(2);
}

.storyContAbs {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 576px) {
  .storyContAbs {
    display: flex;
  }
  .artStyle {
    min-width: 100%;
    max-width: 100%;
    min-height: 183px;
    max-height: 183px;
    object-fit: cover;
  }
  .storyLabel {
    font-size: 12px;
  }
  .typeContainer {
    height: 18px;
  }
  .btnContainer {
    height: 26px;
    width: 100%;
    font-size: 10px;
    justify-content: center !important;
  }
  .submitStoryContainer {
    width: 70px;
    height: 14px;
    top: 10px;
    left: 10px;
  }
  .submitLabel {
    font-size: 7px;
  }

  .btnIcon {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .btnContainer {
    height: 40px !important;
  }
}
