.header{
  border-bottom: none;
}

.btnContainer{
  width:100%
}

.footer{
  border: none;
}

.route{
  margin-right: 10px;
}