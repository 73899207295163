@import "shared/utils/colors.scss";
.statContainer {
  border: 1px solid #e4e4e4;
  border-radius: 5.24px;
  max-width: 251.37px;
  min-width: 241.37px;
  box-shadow: 0px 0.654605px 1.30921px rgba(16, 24, 40, 0.05),
    0px 0px 0px 2.61842px rgba(80, 147, 75, 0.13);
  min-height: 120px;
}

.statstitle {
  height: 14px;
  width: 50px;
}

.statsAmount {
  height: 24px;
  width: 100px;
}

.arrowIcon {
  height: 14px;
  width: 14px;
}

.statsPercent {
  height: 10px;
  width: 30px;
}

.chartLoader {
  height: 54px;
  width: 85px;
}
