@import "shared/utils/colors.scss";

.sendIconContainer {
  height: 44px;
  width: 44px;
  background: #e4e7ec;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.activeComment {
  background: $PRIMARY;
  border: 1px solid $PRIMARY;
  &:hover {
    cursor: pointer;
  }
}



.sendIcon {
  height: 20px;
  width: 20px;
}

.inputStyle {
  min-height: 44px;
  border-radius: 8px;
  border: 1px solid $BORDER;
  padding: 10px;
  outline: 0;
  font-family: InterRegular;
  font-size: 16px;
  color: $DARK_GREY;
  text-align: left;
  &:focus-visible {
    outline: none !important;
  }
  position: relative;
}
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  font-family: InterRegular;
  color: $GREY;
  background-color: transparent;
  cursor: text;
}

.suggestionContainer {
  height: 200px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 44px;
}

.replyCommentContinaer{
  max-width: 300px;
}

@media screen and (max-width:1399px){
  .replyCommentContinaer{
    max-width: 240px;
  }
}

@media screen and (max-width:1199px){
  .replyCommentContinaer{
    max-width: 265px;
  }
}

@media screen and (max-width:992px){
  .replyCommentContinaer{
    max-width: 100%;
  }
}

@media screen and (max-width:767px){
  .replyCommentContinaer{
    max-width: 415px;
  }
}

@media screen and (max-width:550px){
  .replyCommentContinaer{
    max-width: 80%;
  }
}

@media screen and (max-width:400px){
  .replyCommentContinaer{
    max-width: 70%;
  }
}





// 415px
