@import "shared/utils/colors.scss";

.statContainer {
  border: 1px solid #e4e4e4;
  border-radius: 5.24px;
  max-width: 251.37px;
  min-width: 241.37px;
  box-shadow: 0px 0.654605px 1.30921px rgba(16, 24, 40, 0.05),
    0px 0px 0px 2.61842px rgba(80, 147, 75, 0.13);
}

.statstitle {
  font-family: HNMedium;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
  text-transform: capitalize;
}

.statsAmount {
  font-family: HNBold;
  font-size: 24px;
  color: $DARK_GREY;
  text-align: left;
}

.arrowIcon {
  height: 14px;
  width: 14px;
}

.statsPercent {
  font-family: InterRegular;
  font-size: 10px;
  text-align: left;
}

.statsPercentActive {
  color: #027a48;
}

.statsPercentinActive {
  color: #b42318;
}

.lstTxt {
  color: $GREY;
}

.tableContainer {
  border: 1px solid #e4e4e4;
  border-radius: 6.7526px;
}

.tableHeader {
  font-family: HNMedium;
  font-size: 18px;
  color: $DARK_GREY;
  text-align: left;
}

.tableSubTitle {
  font-family: HNRegular;
  font-size: 14px;
  color: $GREY2;
  text-align: left;
}

.seperator {
  height: 2px;
  background-color: #e4e7ec;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .statContainer {
    max-width: 100%;
    min-width: 100%;
  }
}
