.topContainer {
  max-height: 220px;
  min-height: 220px;
}
.topContainer2 {
  max-height: 220px;
  min-height: 168px;
}
.hieght {
  min-height: 30px;
}
.artStyle {
  width: 176px;
  height: 192px;
  object-fit: cover;
}

.iconUpArrow {
  min-height: 24px;
  min-width: 24px;
  margin-top: 3px;
}

.storyLabel {
  height: 22px;
}

.descStyle {
  height: 16px;
}

.categContainer {
  height: 30px;
  width: 140px;
}

.btmBorder {
  height: 1px;
  width: 96%;
  background-color: #e4e7ec;
  position: absolute;
  bottom: 0;
}
.actionIconStyle {
  height: 24px;
  width: 24px;
}

@media (max-width: 576px) {
  .artStyle {
    width: 100%;
  }
}
