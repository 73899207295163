/*// $PRIMARY: #deac00;*/
$PRIMARY: #ACC8CC;
$SECONDARY: #c2d9d3;
$DARK_GREY: #0f1106;
$BLACK: #000000;
$BLACK2: #101828;
$GREEN: #50934b;
$LIGHT_GREY: #e7e7e7;
$GREY: #667085;
$ULTRA_LIGHT_GREY: #f2f4f7;
$ULTRA_DARK_GREY: #344054;
$GRAYWHITE: #e5e4e2;
$GRAYTRANSPARENT: #e5e4e285;
$GREY2: #868e96;
$BORDER: #d0d5dd;
$BG: #F3F2EE;
$RED: #b42318;
$OFF_WHITE: #F3F2EE;
$RED2:#c8352d;
$DARK_BROWN: #62646a;
$PURPLE_BLUE:#2a60ab


