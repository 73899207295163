@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topMainContainer {
  height: 100vh;
  overflow: auto;
}

.mainTitle {
  font-family: ALBold;
  color: black;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  display: flex;
}

.gifStyle {
  height: 450px;
}

.subHeading {
  font-family: HNRegular;
  color: $GREY2;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topMainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topMainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.toplevelContainer {
  height: 372px;
  position: relative;
  background-image: url("../../assets/images/aboutus-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.highlightedText {
  font-family: ALMedium;
  font-size: 32px;
  color: $PRIMARY;
  text-align: left;
}

.overheadStyle {
  width: 62px;
  height: 50px;
}

.headerTitle {
  font-family: ALBold;
  font-size: 46px;
  color: $DARK_GREY;
  text-align: left;
}

.title2 {
  font-family: ALBold;
  font-size: 44px;
  color: $DARK_GREY;
  text-align: left;
  line-height: 52px;
}
.greyLabel {
  font-family: HNRegular;
  font-size: 20px;
  color: $GREY2;
  text-align: left;
  line-height: 30px;
}

.userImg {
  height: 60px;
  width: 60px;
}
.leafIcon {
  height: 24px;
  width: 24px;
}
.userLabel {
  font-family: HNRegular;
  font-size: 24px;
  color: $DARK_GREY;
  text-align: left;
}

.userContainer {
  border-bottom: 1px solid #dee2e6;
}

.bdrTop {
  border-top: 1px solid #dee2e6;
}

.numberOuterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid rgba($color: $PRIMARY, $alpha: 0.3);
  min-height: 48px;
  min-width: 48px;
}

.numberInnerContainer {
  background-color: $PRIMARY;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  filter: drop-shadow(0px 10px 15px rgba(21, 37, 71, 0.15));
}
.title3 {
  font-family: ALBold;
  color: $DARK_GREY;
  text-align: left;
  font-size: 26px;
  line-height: 32px;
}

.greyLabel2 {
  font-family: HNRegular;
  font-size: 18px;
  color: $GREY2;
  text-align: left;
  line-height: 32px;
}

.art1 {
  max-width: 280px;
  max-height: 229px;
  min-width: 200px;
  min-height: 163px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.art2 {
  max-width: 280px;
  max-height: 275px;
  min-width: 200px;
  min-height: 196px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.art3 {
  max-width: 280px;
  max-height: 428px;
  min-width: 200px;
  min-height: 305px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.title4 {
  font-family: ALBold;
  font-size: 40px;
  color: $DARK_GREY;
  text-align: left;
  line-height: 47.19px;
}
.overIcon {
  width: 95px;
  height: 76.61px;
}

.subtitle1 {
  font-family: ALBold;
  font-size: 24px;
  color: $DARK_GREY;
  text-align: left;
  line-height: 28.8px;
}

.btnContainer {
  width: 213px;
  height: 59px;
}

.contactContainer {
  background-color: #fdf8ea;
}

.newsTitle {
  font-family: ALBold;
  font-size: 40px;
  color: $DARK_GREY;
  text-align: center;
}
.newsSubTitle {
  font-family: HNRegular;
  font-size: 20px;
  color: $GREY2;
  text-align: center;
}
.btmLabel {
  font-family: HNRegular;
  font-size: 14px;
  color: $GREY2;
  text-align: left;
}

.prvLabel {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.newsletterInput {
  width: 360px;
  height: 48px;
}

@media (min-width: 300px) and (max-width: 992px) {
  .subHeading {
    padding-top: 40px;
  }
  .gifStyle {
    height: auto;
  }
  .highlightedText {
    font-size: 28px;
  }
  .overheadStyle {
    width: 54px;
    height: 44px;
  }
  .headerTitle {
    font-size: 40px;
  }
  .title2 {
    font-size: 38px;
    line-height: 46px;
  }
  .greyLabel {
    font-size: 18px;
    line-height: 27px;
  }
  .userImg {
    height: 51px;
    width: 51px;
  }
  .leafIcon {
    height: 21px;
    width: 21px;
  }
  .userLabel {
    font-size: 21px;
  }
  .numberOuterContainer {
    min-height: 41px;
    min-width: 41px;
  }
  .numberInnerContainer {
    height: 31px;
    width: 31px;
  }
  .title3 {
    font-size: 22px;
    line-height: 28px;
  }
  .greyLabel2 {
    font-size: 16px;
    line-height: 28px;
  }

  .art1 {
    max-width: 200px;
    max-height: 163px;
    min-width: 172px;
    min-height: 140px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .art2 {
    max-width: 200px;
    max-height: 196px;
    min-width: 172px;
    min-height: 168px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .art3 {
    max-width: 200px;
    max-height: 305px;
    min-width: 172px;
    min-height: 262px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .title4 {
    font-size: 34px;
    line-height: 40.7px;
  }
  .overIcon {
    width: 82px;
    height: 66px;
  }

  .subtitle1 {
    font-size: 21px;
    line-height: 24.8px;
  }
  .btnContainer {
    width: 183px;
    height: 51px;
  }
  .newsTitle {
    font-size: 33.333px;
  }
  .newsSubTitle {
    font-size: 16.66667px;
  }
}

@media screen and (max-width: 767px) {
  .bdrTop {
    border-top: none;
  }
  .respTopContainer {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .newsTitle {
    font-size: 25px !important;
  }

  .coulmn2 {
    width: 100% !important;
    height: 400px !important;
    object-fit: cover;
  }
}
