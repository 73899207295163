@import "shared/utils/colors.scss";

thead tr th {
  font-weight: 500;
  text-align: left;
  background-color: #f9fafb !important;
  width: 100%;
}
td {
  padding: 24px 0.5rem !important;
  font-size: 18px;
  font-family: HNRegular;
  color: $DARK_GREY;
  overflow-wrap: break-word;
  text-align: left;
}
th {
  padding: 15px 0;
}
th {
  font-size: 15px;
  font-family: HNMedium;
  color: $DARK_GREY;
  white-space: nowrap;
}

tr {
  border-bottom: 1px solid #e4e7ec !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.paddingLeft {
  padding-left: 27px !important;
}

.td {
  padding-top: 32px !important;
  width: 100%;
}

.IconSyle {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f7fb;
  border-radius: 50px;
}

.labelLoader {
  height: 15px;
  width: 100px;
}

.noContentTopContainer {
  min-height: 450px;
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreBtn {
  border: 0px;
  outline: 0px;
  background-color: white;
  font-family: HNRegular;
  font-size: 18px;
  color: $PRIMARY;
  text-decoration: underline;
}
