@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
.contestCardContainer {
  background-color: white;
}

.artStyle {
  height: 285px;
  width: 100%;
  object-fit: cover;
}

.submitLabel {
  font-family: HNRegular;
  font-size: 14px;
  text-align: left;
  color: black;
  &:hover {
    cursor: pointer;
  }
}

.readMoreText {
  font-family: HNRegular;
  font-size: 16px;
  color: #868e96;
  text-align: left;
}

.hieght {
  min-height: 30px;
}

.submitStoryContainer {
  position: absolute;
  top: 10px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: fit-content;
  padding-left: 14px;
  padding-right: 14px;
  background-color: #fdf8ea;
  border-radius: 24px;
  &:hover {
    cursor: pointer;
  }
}

.submitLabel {
  font-family: HNRegular;
  font-size: 14px;
  text-align: left;
  color: black;
  &:hover {
    cursor: pointer;
  }
}

.appertureIcon {
  height: 18px;
  width: 18px;
}
.appertureIcon path {
  stroke: black;
}
.descStyle {
  font-family: HNRegular;
  font-size: 16px;
  color: $GREY2;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 78px;
}

.actionContainer {
  position: relative;
  bottom: 0;
  padding-right: 12px;
  margin-top: 1rem;
}

.contributeLabel {
  font-family: HNMedium;
  font-size: 16px;
  color: $PRIMARY;
  &:hover {
    cursor: pointer;
  }
}

.btnContainer {
  background-color: transparent;
  border: 1px solid $DARK_GREY;
}

.iconStyle {
  height: 24px;
  width: 24px;
}

.iconStyle path {
  stroke: $PRIMARY;
}

@media (max-width: 576px) {
  .artStyle {
    height: 216px;
  }
  .hieght {
    min-height: 21px;
  }
  .contributeLabel {
    font-size: 8px;
  }
  .iconStyle {
    height: 12px;
    width: 12px;
  }
  .readMoreText {
    font-size: 14px;
  }
  .btnIcon {
    height: 12px;
    width: 12px;
  }
  .descStyle {
    font-size: 14px;
  }
  .submitStoryContainer {
    height: 32px;
    top: 10px;
    left: 20px;
  }
  .submitLabel {
    font-size: 14px;
  }
  .appertureIcon {
    height: 14px;
    width: 14px;
  }
  .btnContainer {
    height: 40px;
    width: 100%;
    font-size: 14px;
    justify-content: center !important;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .contributeLabel {
    font-size: 14px;
  }
  .iconStyle {
    height: 20px;
    width: 20px;
  }
  .btnContainer {
    height: 40px;
  }
}

@media screen and (max-width: 992px) {
  .actionContainer {
    padding-right: 0px;
  }
}
