@import "shared/utils/colors.scss";
.short_clip {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: $GRAYWHITE;
}
.skeletonLoader {
  animation: scale 0.4s linear infinite alternate;
}

@keyframes scale {
  0% {
    background-color: $GRAYTRANSPARENT;
  }
  100% {
    background-color: $GRAYWHITE;
  }
}
