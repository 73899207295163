@import "shared/utils/colors.scss";

.crossIcon {
  height: 24px;
  width: 24px;
}
.crossIcon path {
  stroke: $GREY;
  fill: $GREY;
}
.logo {
  width: 91.25px;
  height: 45.33px;
}
.headText {
  font-family: HNRegular;
  font-size: 16px;
  color: $GREY2;
  text-align: center;
}
.forgetText {
  font-family: HNMedium;
  font-size: 14px;
  color: $DARK_GREY;
}

.divider {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;
}

.divider::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e4e7ec;
  content: "";
}

.dividerTxt {
  position: relative;
  z-index: 2;
  padding: 0 10px;
  background: #fff;
  font-weight: 500;
  color: $GREY2;
  font-size: 14px;
  font-family: InterRegular;
}

.btmText1 {
  font-size: 14px;
  font-family: HNRegular;
  color: $GREY2;
}

.btmText2 {
  font-size: 14px;
  font-family: HNMedium;
  color: $DARK_GREY;
}
