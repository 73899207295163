@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-check-input:checked {
  background-color: #acc8cc !important;
  border-color: #acc8cc !important;
}

.form-check-input:focus {
  outline: none !important;
}

@font-face {
  font-family: "AG";
  src: url("../src/assets/fonts/Super\ Retro\ M54.ttf");
}
@font-face {
  font-family: "Quincy";
  src: url("../src/assets/fonts/Fontspring-DEMO-quincycf-mediumitalic.otf");
}

@font-face {
  font-family: "LG";
  src: url("../src/assets/fonts/laguserif-lightitalic.otf");
}

@font-face {
  font-family: "LGBold";
  src: url("../src/assets/fonts/LaguSerif-ExtraBold.ttf");
}

@font-face {
  font-family: "LGSemiBold";
  src: url("../src/assets/fonts/LaguSerif-SemiBold.ttf");
}

@font-face {
  font-family: "InterLight";
  src: url("../src/assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "InterMedium";
  src: url("../src/assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "InterRegular";
  src: url("../src/assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "InterSemiBold";
  src: url("../src/assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "InterBold";
  src: url("../src/assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "InterExtraBold";
  src: url("../src/assets/fonts/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: "HNRegular";
  src: url("../src/assets/fonts/HelveticaNeue.ttf");
}

@font-face {
  font-family: "HNMedium";
  src: url("../src/assets/fonts/HelveticaNeue-Medium.ttf");
}

@font-face {
  font-family: "HNBold";
  src: url("../src/assets/fonts/Helvetica-Neu-Bold.ttf");
}

@font-face {
  font-family: "ALMedium";
  src: url("../src/assets/fonts/AbhayaLibre-Medium.ttf");
}

@font-face {
  font-family: "ALRegular";
  src: url("../src/assets/fonts/AbhayaLibre-Regular.ttf");
}

@font-face {
  font-family: "ALSemiBold";
  src: url("../src/assets/fonts/AbhayaLibre-SemiBold.ttf");
}

@font-face {
  font-family: "ALBold";
  src: url("../src/assets/fonts/AbhayaLibre-Bold.ttf");
}

@font-face {
  font-family: "ALExtraBold";
  src: url("../src/assets/fonts/AbhayaLibre-ExtraBold.ttf");
}

@font-face {
  font-family: "Gotham";
  src: url("../src/assets/fonts/Gotham-Book.otf");
}
