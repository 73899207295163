@import "shared/utils/colors.scss";

.iconStyle {
  width: 68px;
  height: 68px;
}

.title {
  font-family: HNMedium;
  font-size: 25px;
  color: $DARK_GREY;
  text-align: left;
  line-height: 40px;
}

.subTitle {
  font-family: HNRegular;
  font-size: 18px;
  color: $GREY2;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .iconStyle {
    width: 56px;
    height: 56px;
  }

  .title {
    font-size: 21px;
    line-height: 34px;
  }

  .subTitle {
    font-size: 15px;
  }
}
