@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.artContainer {
  border-bottom: 1px solid #e4e7ec;
}

.btnContainer {
  background-color: transparent;
  border: 1px solid $DARK_GREY;
}

.annouceIconStyle {
  height: 12px;
  width: 12px;
}

.timerContainer {
  border-radius: 16px;
  background: #ecfdf3;
  padding: 2px 8px 2px 6px;
}

.timerLabel {
  font-size: 12px;
  font-family: InterMedium;
  color: #027a48;
}

.containerBtn {
  justify-content: space-between;
  padding: 13px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: fit-content !important;
  background: #0000005e !important;
  border-radius: 0px;
}

.pendingContainer {
  height: 22px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ULTRA_LIGHT_GREY;
}

.pendingLabel {
  font-size: 12px;
  font-family: InterMedium;
  color: $GREY2;
}

.customIcon {
  height: 20px;
  width: 20px;
  fill: white;
}

.customBtnContainer {
  background: #00000052 !important;
  border-radius: 0px;
  padding: 13px;
}

.artPicLoader {
  height: 487px;
  width: 100%;
}

.artPic {
  // max-width: 696px;
  max-height: 487px;
  // min-height: 487px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.height {
  min-height: 30px;
}

.viewsContainer {
  background-color: $ULTRA_LIGHT_GREY;
  border-radius: 16px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.viewLabel {
  font-size: 12px;
  font-family: HNMedium;
  color: $ULTRA_DARK_GREY;
}

.desc {
  font-size: 20px;
  font-family: HNRegular;
  text-align: left;
  color: $GREY2;
}

.descLabel {
  font-size: 20px;
  font-family: HNRegular;
  color: $GREY2;
  text-align: left;
}

.descLoader {
  height: 20px;
}

.submitStoryContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #fdf8ea;
  padding: 8px 12px 8px 12px;
  border-radius: 24px;
  &:hover {
    cursor: pointer;
  }
}

.submitLabel {
  font-family: HNRegular;
  font-size: 14px;
  text-align: left;
  color: black;
  &:hover {
    cursor: pointer;
  }
}

.tagContainer {
  border-radius: 5px;
  border: 1px solid $BORDER;
}

.tag {
  font-size: 14px;
  font-family: HNMedium;
  color: $DARK_GREY;
}

.tagLabel {
  font-size: 16px;
  font-family: HNRegular;
  color: $GREY2;
}

.appertureIcon {
  height: 18px;
  width: 18px;
}

.appertureIcon path {
  stroke: black;
}

.contributeLabel {
  font-family: ALBold;
  font-size: 24px;
  text-align: left;
  color: $DARK_GREY;
}

.categLoader {
  width: 120px;
}

.greenActionIconStyle {
  height: 24px;
  width: 24px;
}

.greenActionIconStyle path {
  stroke: $PRIMARY;
}

.highlightedLabel {
  font-family: HNRegular;
  font-size: 16px;
  color: $PRIMARY;
}

.greyActionIconStyle {
  height: 24px;
  width: 24px;
}

.greyActionIconStyle path {
  stroke: $GREY2;
}

.actionlabel {
  font-family: HNRegular;
  font-size: 16px;
  color: $GREY2;
}

.topMainContainer {
  height: 100vh;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topMainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topMainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
