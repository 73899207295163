@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.artContainer {
  min-width: 383px;
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.artStyle {
  width: 100%;
  height: 300px;
  object-fit: fill;
}

.infoContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.89);
}

.infoTitle {
  font-family: ALBold;
  font-size: 26px;
  color: $DARK_GREY;
  text-align: left;
  @include elipsis(1);
}

.infoSubTitle {
  font-family: HNRegular;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
  @include elipsis(6);
}

.moreIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}
.title {
  font-size: 26px;
  font-family: ALBold;
  color: $DARK_GREY;
  text-align: left;
}

.optionsContainer {
  width: 189px !important;
  right: 10px;
  top: 20px;
  z-index: 2;
}

.pendingContainer {
  max-height: 30px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ULTRA_LIGHT_GREY;
}

.pendingLabel {
  font-size: 12px;
  font-family: InterMedium;
  color: $GREY2;
}

.bttnContainer {
  border: 1px solid $DARK_GREY;
  outline: 0;
  background-color: white;
  color: $DARK_GREY;
  font-size: 16px;
  font-family: HNRegular;
  width: 158px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.btnIcon {
  height: 20px;
  width: 20px;
}

.default {
  position: absolute;
  right: 18px;
  top: 18px;
  background: $SECONDARY;
  border: 1px solid $SECONDARY;
  font-family: HNMedium;
  font-size: 12px;
  color: #344054;
  border-radius: 16px;
  width: fit-content;
  height: 28px;
}

@media screen and (max-width: 992px) {
  .optionsContainer {
    right: 10px;
  }
}

@media screen and (max-width: 576px) {
  .artContainer {
    min-width: 96px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .artContainer {
    min-width: 96px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .artContainer {
    min-width: 96px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .artContainer {
    min-width: 96px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .artContainer {
    min-width: 96px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1400px) {
  .artContainer {
    min-width: 383px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
