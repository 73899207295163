@import "shared/utils/colors.scss";

.title {
  font-family: ALBold;
  font-size: 18px;
  color: $BLACK;
  text-align: left;
}

.subTitle {
  font-family: HNRegular;
  font-size: 14px;
  color: $GREY2;
  text-align: left;
}

.seperator {
  background-color: #e4e7ec;
  height: 1px;
  width: 100%;
}

.titleLoader {
  height: 28px;
  width: 169px;
}

.switchLoader {
  height: 18px;
  width: 33px;
  border-radius: 50px;
}

.subtitleLoader {
  height: 24px;
  max-width: 700px;
  width: 100%;
}
