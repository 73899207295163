@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
@import "shared/components/storyCard/style.module.scss";

.sampleImg {
  width: 752px;
  height: 303px;
  object-fit: cover;
  margin-top: 48px;
}

.sampleHeading {
  font-family: ALBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;

  span {
    color: $PRIMARY;
  }
}

.sampleButton {
  width: 752px;
  height: 44px;
  background: $PRIMARY;
  border: 1px solid $PRIMARY;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  margin-top: 50px;
  color: black;
  &:hover {
    background: $PRIMARY;
    border: 1px solid $PRIMARY;
    color: black;
  }

  &:active {
    background: $PRIMARY !important;
    border: 1px solid $PRIMARY !important;
    color: black;
  }
}

.sampleDiv {
  width: 752px;
}

.sampleSubHeading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $GREY2;
}

.uploadImageContainer {
  width: 752px;
  height: 126px;
  background: #ffffff;
  border: 1px dashed #e4e7ec;
  border-radius: 20px;
  margin-top: 32px;
  overflow: hidden;
}

.content {
  // width: 800px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  padding: 24px 24px 48px;
}

.cross {
  cursor: pointer;
  height: 36px;
  width: 36px;

  path {
    stroke: $GREY;
    fill: $GREY;
    stroke-width: 0px;
  }
}

.labelAddArt {
  font-family: ALBold;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #0f1106;
}

.publishButton {
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  width: 752px;
  height: 44px;
  background: $PRIMARY;
  border: 1px solid $PRIMARY;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  color: black;
  &:hover {
    background: $PRIMARY;
    border: 1px solid $PRIMARY;
    color: black;
  }

  &:active {
    background: $PRIMARY !important;
    border: 1px solid $PRIMARY !important;
    color: black;
  }
}

.disabledButton {
  background: #adb5bd !important;
  border: 1px solid #adb5bd !important;

  &:hover {
    background: #adb5bd;
    border: 1px solid #adb5bd;
  }
}

.textAreaComp {
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  width: 100%;
  height: 148px;
  padding: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $DARK_GREY;
  margin-top: 32px;

  &:focus-visible {
    outline: none;
  }
}

.uploadedImageContainer {
  border: 1.5px solid $PRIMARY !important;
}

.textAreaLength {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 32px;
}

.modalBody {
  padding: 0px !important;
}

.headlineInput {
  outline: 0px;
  height: 52px;
  width: 100%;
  border: 1px solid $BORDER;
  background-color: #f9fafb;
  border-radius: 24px;
  font-family: HNBold;
  color: $DARK_GREY;
  font-size: 24px;
  margin-top: 3%;
}

@mixin dialogContent {
  overflow-y: auto;
  min-width: fit-content;
}

.addArtModal {
  @include dialogContent();
}

.addArtLabel {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 820px) {
  // .content {
  //   width: 800px;
  // }

  @mixin dialogContent {
    overflow-y: auto;
    min-width: auto !important;
  }

  .addArtModal {
    @include dialogContent();
  }

  .uploadImageContainer {
    width: 100% !important;
  }

  .publishButton {
    width: 100% !important;
  }

  .sampleImg {
    width: auto;
  }

  .sampleButton {
    width: 100%;
  }

  .sampleDiv {
    width: auto;
  }
}
