@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.subTitle {
  font-family: HNMedium;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
}

.genresListContainer {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.genresListContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.genresListContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.genreContainer {
  height: 22px;
  border-radius: 16px;
  background-color: #f2f4f7;
  width: fit-content;
}

.genreLabel {
  font-family: HNMedium;
  font-size: 12px;
  color: $DARK_GREY;
}

.genreCrossIcon {
  height: 20px;
  width: 20px;
}

.tagsContainer {
  min-height: 44px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid $BORDER;
  display: flex;
  flex-wrap: wrap;
}

.tagsListContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.error2 {
  color: #ea5b5b;
  font-size: 11px;
  font-family: HNRegular;
  text-align: left;
  position: absolute;
  bottom: -18px;
  left: 0px;
  z-index: 1;
}

.optionsContainer {
  width: 189px !important;
  right: -170px;
  top: 30px;
  z-index: 2;
}

.tagsInput {
  border-radius: 24px;
  border: 0px;
  outline: 0px;
  width: fit-content;
}

.tagsHoverContainer {
  position: absolute;
  width: 100% !important;
  right: 0px;
  bottom: 50px;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 8px;
  max-height: 220px;
  min-height: 100px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
}

.spinnerContainer {
  position: absolute;
  width: 100% !important;
  right: 0px;
  bottom: -100px;
  z-index: 2;
  min-height: 100px;
  max-height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
}

.allTagsHoverContainer {
  position: absolute;
  width: 100% !important;
  right: 0px;
  bottom: -220px;
  z-index: 2;
  max-height: 220px;
  min-height: 220px;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
}

.allTagsHoverContainer::-webkit-scrollbar {
  width: 3px;
}

.allTagsHoverContainer::-webkit-scrollbar-thumb {
  background-color: $PRIMARY;
  border-radius: 100px;
}

.allTagsHoverContainer::-webkit-scrollbar-track {
  border: 1px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  background-color: #f1f3f6;
}

.labelStyle {
  font-size: 14px;
  font-family: HNRegular;
  color: $DARK_GREY;
}

.optionContainer {
  border-bottom: 1px solid $BORDER;
  &:hover {
    background-color: $BG;
  }
}

.topradius {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.btmradius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tagSelectiontitle {
  color: $DARK_GREY;
  font-family: HNRegular;
  font-size: 16px;
}

.optionContainerLoader {
  height: 54px;
  width: 100%;
}

.active {
  background-color: $BG;
}
