@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.optionsContainer {
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  top: 45px;
  right: 0px;
  width: 250px !important;
}
.optionContainer {
  &:hover {
    background-color: $BG;
  }
}

.btmradius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.nameLabel {
  font-family: ALBold;
  font-size: 16px;
  color: $DARK_GREY;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: inherit;
}

.emailLabel {
  font-family: HNRegular;
  font-size: 14px;
  color: $GREY;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: inherit;
}

.seperator {
  height: 2px;
  width: 100%;
  background-color: $ULTRA_LIGHT_GREY;
}

.optionIcon {
  height: 16px;
  width: 16px;
}

.optionlabel {
  font-family: HNRegular;
  font-size: 14px;
  color: $DARK_GREY;
}
