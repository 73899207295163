@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.avatarStyle {
  width: 176px;
  height: 176px;
  border-radius: 100%;
  object-fit: cover;
}

.title {
  @include elipsis(1);
  font-size: 18px;
  color: $DARK_GREY;
}

.subTitle {
  font-size: 14px;
  color: #808080;
}

@media screen and (max-width: 480px){
  .avatarStyle{
    width: 120px;
    height: 120px;
  }
}
