@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.artContainer {
  min-width: 383px;
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.artStyle {
  width: 100%;
  height: 415px;
  object-fit: fill;
}

.infoContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.89);
}

.infoTitle {
  font-family: ALBold;
  font-size: 26px;
  color: $DARK_GREY;
  text-align: left;
  @include elipsis(1);
}

.infoSubTitle {
  font-family: HNRegular;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
  @include elipsis(6);
}

.moreIcon {
  width: 24px;
  height: 24px;
}
.title {
  font-size: 26px;
  font-family: ALBold;
  color: $DARK_GREY;
  text-align: left;
}

.optionsContainer {
  width: 189px !important;
  right: -170px;
  top: 20px;
  z-index: 2;
}

@media screen and (max-width: 992px) {
  .optionsContainer {
    right: 10px;
  }
}

@media screen and (max-width: 576px) {
  .artContainer {
    min-width: 96px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .artContainer {
    min-width: 96px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .artContainer {
    min-width: 96px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .artContainer {
    min-width: 96px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .artContainer {
    min-width: 96px;
    width: 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media screen and (min-width: 1400px) {
  .artContainer {
    min-width: 383px;
    width: 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}
