@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topMainContainer {
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topMainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topMainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.coverContainer {
  height: 424px;
  background: rgba(131, 136, 143, 0.5);
}

.avatar {
  height: 96px;
  width: 96px;
  border-radius: 100%;
  object-fit: cover;
  border: 5px solid white;
}

.name {
  font-size: 52px;
  font-family: ALBold;
  color: white;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 48px;
}

.bio {
  font-size: 20px;
  font-family: HNRegular;
  color: white;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.editCoverContainer {
  position: absolute;
  right: 0px;
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

.cameraIcon {
  height: 20px;
  width: 20px;
}

.editLabel {
  font-family: InterMedium;
  font-size: 14px;
  color: white;
  &:hover {
    cursor: pointer;
  }
}

.donationContainer {
  border-radius: 18px;
  background-color: $BG;
  padding: 27px 30px;
  display: flex;
  align-items: center;
}

.donationAmount {
  font-family: ALBold;
  color: $PRIMARY;
  font-size: 48px;
}

.donationLabel {
  font-family: ALBold;
  color: $DARK_GREY;
  font-size: 30px;
}

.donationAmountLoader {
  width: 100%;
  height: 30px;
}

.winnerLabel {
  font-size: 20px;
  font-family: InterRegular;
  color: white;
}

.winnerTimes {
  font-family: InterSemiBold;
}

.seperator {
  height: 1px;
  background-color: $BORDER;
  width: 100%;
}

.title {
  font-family: ALBold;
  font-size: 32px;
  line-height: 38px;
  color: $DARK_GREY;
  text-align: left;
}

.subTitle {
  font-family: HNRegular;
  font-size: 18px;
  color: $GREY2;
  text-align: left;
}

.cstmBtn {
  width: 260px;
  height: 36px;
  font-size: 14px;
  font-family: InterMedium;
}

.btIcon {
  height: 20px;
  width: 20px;
}

.subTitle2 {
  font-family: ALBold;
  font-size: 22px;
  line-height: 28px;
  color: $DARK_GREY;
  text-align: left;
}

.reportLabel {
  font-size: 18px;
  font-family: ALRegular;
  color: #f31f28;
  text-align: left;
}

.flagIcon {
  height: 24px;
  width: 24px;
}

.flagIcon path {
  stroke: #f31f28;
}

@media screen and (max-width: 576px) {
  .editCoverContainer {
    right: 20px;
    top: -30px;
  }
  .cameraIcon {
    height: 18px;
    width: 18px;
  }

  .editLabel {
    font-size: 12px;
  }
  .donationContainer {
    padding: 24px 30px;
  }

  .donationAmount {
    font-size: 33px;
  }

  .donationLabel {
    font-size: 21px;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 400px) {
  .coverContainer {
    height: 260px;
  }
  .avatar {
    height: 60px;
    width: 60px;
  }

  .name {
    font-size: 32px;
  }

  .bio {
    font-size: 12px;
  }
  .donationContainer {
    padding: 24px 30px;
  }

  .donationAmount {
    font-size: 33px;
  }

  .donationLabel {
    font-size: 21px;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .coverContainer {
    height: 326px;
  }
  .avatar {
    height: 73px;
    width: 73px;
    border: 3px solid white;
  }

  .name {
    font-size: 40px;
  }

  .bio {
    font-size: 16px;
  }
  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 33px;
  }

  .donationLabel {
    font-size: 21px;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (min-width: 576px) {
  .coverContainer {
    height: 326px;
  }
  .avatar {
    height: 73px;
    width: 73px;
    border: 3px solid white;
  }

  .name {
    font-size: 40px;
  }

  .bio {
    font-size: 16px;
  }

  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 33px;
  }

  .donationLabel {
    font-size: 21px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (min-width: 768px) {
  .coverContainer {
    height: 326px;
  }
  .avatar {
    border: 3px solid white;
    height: 73px;
    width: 73px;
  }

  .name {
    font-size: 40px;
  }

  .bio {
    font-size: 16px;
  }

  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 33px;
  }

  .donationLabel {
    font-size: 21px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (min-width: 992px) {
  .coverContainer {
    height: 356px;
  }
  .avatar {
    border: 3px solid white;
    height: 81px;
    width: 81px;
  }

  .name {
    font-size: 44px;
  }

  .bio {
    font-size: 17px;
  }
  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 33px;
  }

  .donationLabel {
    font-size: 21px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .coverContainer {
    height: 356px;
  }
  .avatar {
    border: 3px solid white;
    height: 81px;
    width: 81px;
  }

  .name {
    font-size: 44px;
  }

  .bio {
    font-size: 17px;
  }
  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 36px;
  }

  .donationLabel {
    font-size: 23px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1400px) {
  .coverContainer {
    height: 391px;
  }
  .avatar {
    border: 4px solid white;
    height: 88px;
    width: 88px;
  }

  .name {
    font-size: 48px;
  }

  .bio {
    font-size: 18.46px;
  }
  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 40px;
  }

  .donationLabel {
    font-size: 25px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .subTitle {
    font-size: 15px;
  }

  .cstmBtn {
    width: 220px;
    height: 30px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .coverContainer {
    height: 391px;
  }
  .avatar {
    border: 4px solid white;
    height: 88px;
    width: 88px;
  }

  .name {
    font-size: 48px;
  }

  .bio {
    font-size: 18.46px;
  }
  .donationContainer {
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 44px;
  }

  .donationLabel {
    font-size: 27px;
  }

  .title {
    font-size: 29px;
    line-height: 35px;
  }

  .subTitle {
    font-size: 16px;
  }

  .cstmBtn {
    width: 240px;
    height: 33px;
    font-size: 13px;
  }

  .btIcon {
    height: 18px;
    width: 18px;
  }

  .subTitle2 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1800px) {
  .coverContainer {
    height: 424px;
  }
  .avatar {
    border: 5px solid white;
    height: 96px;
    width: 96px;
  }

  .name {
    font-size: 52px;
  }

  .bio {
    font-size: 20px;
  }

  .donationContainer {
    border-radius: 18px;
    padding: 27px 30px;
  }

  .donationAmount {
    font-size: 48px;
  }

  .donationLabel {
    font-size: 30px;
  }

  .title {
    font-size: 32px;
    line-height: 38px;
  }

  .subTitle {
    font-size: 18px;
  }

  .cstmBtn {
    width: 260px;
    height: 36px;
    font-size: 14px;
  }

  .btIcon {
    height: 20px;
    width: 20px;
  }

  .subTitle2 {
    font-size: 22px;
    line-height: 28px;
  }
}
