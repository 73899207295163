@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topMainContainer {
  height: 100vh;
  overflow: auto;
}

.noContentIcon{
  height: 150px;
  width: 150px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topMainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topMainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.optionsContainerLoader {
  max-width: 287px;
  width: 100%;
  height: 236px;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 28px rgba(7, 20, 53, 0.11));
}

.optionsContainer {
  max-width: 287px;
  width: 100%;
  border-radius: 6px;
  background-color: white;
  filter: drop-shadow(0px 4px 28px rgba(7, 20, 53, 0.11));
}
.optionContainer {
  &:hover {
    cursor: pointer;
    background-color: $PRIMARY;
    .optionTitle {
      color: black;
    }
    .arrowIcon path {
      fill: black;
    }
  }
}

.optionTitle {
  font-family: ALBold;
  font-size: 18px;
  color: $DARK_GREY;
  text-align: left;
}

.arrowIcon {
  width: 6.21px;
  height: 11.62px;
}

.bgColor {
  background-color: $PRIMARY;
}

.bgColor label {
  color: black;
}

.bgColor svg path {
  fill: black;
}

.brdTop {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.brdbtm {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.questionContainer {
  border-bottom: 1px solid #dde1e4;
  border-left: 2px solid transparent;
  &:hover {
    cursor: pointer;
  }
}

.brdLeft {
  border-left: 2px solid $PRIMARY;
}

.brdTop2 {
  border-top: 1px solid #dde1e4;
}

.iconContainer {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $BG;
  border-radius: 100%;
}



.iconStyle {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
}

.subTitle {
  font-size: 14px;
  font-family: HNRegular;
  color: $GREY;
  text-align: left;
  align-self: flex-start;
}

.faqLabel {
  font-family: HNBold;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
}

.faqAnswer {
  font-family: HNRegular;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
}

.faqLabelLoader {
  height: 14px;
  width: 90%;
}

@media screen and (max-width: 576px) {
  .optionsContainer {
    max-width: 100%;
  }
}
