@import "shared/utils/colors.scss";

.avatarStyle {
  width: 176px;
  height: 176px;
  border-radius: 100%;
}

.title {
  height: 18px;
}

.subTitle {
  height: 14px;
}
