@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topMainContainer {
  height: 100vh;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topMainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topMainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hieght {
  min-height: 30px;
}

.actionIconStyle1 {
  height: 24px;
  width: 24px;
}

.actionIconStyle1 path {
  stroke: $DARK_GREY;
}

.actionLabel1 {
  font-size: 16px;
  font-family: HNRegular;
  color: $DARK_GREY;
}

.storyTitle {
  font-family: ALBold;
  font-size: 47px;
  color: $DARK_GREY;
  text-align: left;
}

.art {
  width: 100%;
  object-fit: cover;
}

.descLabel {
  font-size: 20px;
  font-family: HNRegular;
  color: $GREY2;
  text-align: left;
}

.brdBtm {
  border-bottom: 1px solid #e4e7ec;
}

.heading {
  font-family: ALBold;
  font-size: 30px;
  color: $DARK_GREY;
  text-align: left;
}

.detail {
  font-size: 18px;
  font-family: HNRegular;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}

.desc {
  font-size: 20px;
  font-family: HNRegular;
  text-align: left;
  color: $GREY2;
}

.heading2 {
  font-family: ALBold;
  font-size: 24px;
  color: $DARK_GREY;
  text-align: left;
}

.tagLabel {
  font-size: 16px;
  font-family: HNRegular;
  color: $GREY2;
}

.tagContainer {
  border-radius: 5px;
  border: 1px solid $BORDER;
}

.tag {
  font-size: 14px;
  font-family: HNMedium;
  color: $DARK_GREY;
}

.actionIconStyle {
  height: 24px;
  width: 24px;
}

.actionlabel {
  font-family: HNRegular;
  font-size: 16px;
  color: $GREY2;
}

.greyActionIconStyle {
  height: 24px;
  width: 24px;
}

.greyActionIconStyle path {
  stroke: $GREY2;
}

.greenActionIconStyle {
  height: 24px;
  width: 24px;
}

.greenActionIconStyle path {
  stroke: $PRIMARY;
}

.highlightedLabel {
  font-family: HNRegular;
  font-size: 16px;
  color: $PRIMARY;
}

.moreLabel {
  font-family: ALBold;
  font-size: 24px;
  color: $PRIMARY;
  text-align: left;
}

.pendingContainer {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ULTRA_LIGHT_GREY;
  border-radius: 16px;
}

.pendingLabel {
  font-size: 12px;
  font-family: HNMedium;
  color: $GREY2;
}

.categLoader {
  width: 120px;
}

.storyTitleLoader {
  height: 47px;
}

.artLoader {
  height: 436px;
  width: 100%;
}

.detailLoader {
  height: 18px;
}

.tagLabelLoader {
  height: 24px;
  width: 48px;
}

.tagContainerloader {
  width: 73px;
  height: 36px;
}

.authorCardLoader {
  height: 222px;
  border-radius: 8px;
}

.noContentIconStyle {
  width: 198px;
  height: 146px;
}

.noContentLabel {
  font-size: 16px;
}

@media screen and (max-width: 576px) {
  .storyTitle {
    font-size: 30px;
    line-height: 40px;
  }
}
