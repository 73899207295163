@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topMainContainer {
  height: 100vh;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topMainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topMainContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.questionContainer {
  border-bottom: 1px solid #dde1e4;
  &:hover {
    cursor: pointer;
  }
}

.brdTop2 {
  border-top: 1px solid #dde1e4;
}

.iconContainer {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $BG;
  border-radius: 100%;
}

.faqLabel {
  font-family: HNRegular;
  font-size: 18px;
  color: $DARK_GREY;
  text-align: left;
}

.faqAnswer {
  font-family: HNRegular;
  font-size: 18px;
  color: $DARK_GREY;
  text-align: left;
  border-bottom: 1px solid #dde1e4;
}

.title {
  font-family: ALBold;
  font-size: 34px;
  color: $DARK_GREY;
  text-align: left;
}

.contactContainer {
  background-color: #fdf8ea;
}

.contactTitle {
  font-family: ALBold;
  font-size: 24px;
  color: $DARK_GREY;
  text-align: left;
}
.contactSubTitle {
  font-family: HNRegular;
  font-size: 18px;
  color: $GREY;
  text-align: left;
}

.getusLabel {
  font-family: HNBold;
  font-size: 16px;
  color: $PRIMARY;
  text-align: left;
}

.aboutTitle {
  font-family: ALBold;
  font-size: 36px;
  color: $DARK_GREY;
  text-align: left;
}

.aboutSubtitle {
  font-family: HNRegular;
  font-size: 20px;
  color: $GREY;
  text-align: left;
}

.img1 {
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img2 {
  max-width: 160px;
  max-height: 240px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img3 {
  width: 100%;
  height: 100%;
  max-width: 192px;
  max-height: 192px;
  object-fit: cover;
}

.img4 {
  max-width: 160px;
  max-height: 240px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img5 {
  width: 100%;
  height: 100%;
  max-width: 192px;
  max-height: 160px;
  object-fit: cover;
}

.newsTitle {
  font-family: ALBold;
  font-size: 40px;
  color: $DARK_GREY;
  text-align: center;
}
.newsSubTitle {
  font-family: HNRegular;
  font-size: 20px;
  color: $GREY2;
  text-align: center;
}
.btmLabel {
  font-family: HNRegular;
  font-size: 14px;
  color: $GREY2;
  text-align: left;
}

.prvLabel {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.newsletterInput {
  width: 360px;
  height: 48px;
}

.requestContainer {
  border: 1px solid #e4e4e4;
  border-radius: 12px;
}

.crossIcon {
  height: 34px;
  width: 34px;
}

.crossIcon path {
  fill: $GREY;
}

.crossLabel {
  font-family: ALBold;
  font-size: 28px;
  color: $DARK_GREY;
  text-align: left;
}

.requestInputLabel {
  font-family: HNMedium;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .img1 {
    max-width: 100px;
    max-height: 100px;
  }

  .img2 {
    max-width: 100px;
    max-height: 150px;
  }
  .img3 {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 120px;
    object-fit: cover;
  }

  .img4 {
    max-width: 100px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img5 {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 100px;
    object-fit: cover;
  }

  .aboutTitle {
    font-size: 30px;
  }

  .aboutSubtitle {
    font-size: 16.66667px;
  }

  .faqLabel {
    font-size: 15px;
  }

  .faqAnswer {
    font-size: 15px;
  }

  .title {
    font-size: 28.33333px;
  }

  .contactTitle {
    font-size: 20px;
  }
  .contactSubTitle {
    font-size: 15px;
  }

  .newsTitle {
    font-size: 33.333px;
  }
  .newsSubTitle {
    font-size: 16.66667px;
  }
}
