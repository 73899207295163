@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
@import "shared/components/storyCard/style.module.scss";

@mixin dialogContent {
  overflow-y: auto;
  min-width: fit-content;
}

.reportDialog {
  @include dialogContent();
}

.column {
  padding: 5px !important;
}

.labelText {
  font-family: ALBold;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #0f1106;
  margin-top: 28px;
}

.optionsText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #1a1a1a;
}

.textAreaComp {
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  border-radius: 24px;
  width: 100%;
  height: 148px;
  padding: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $DARK_GREY;
  margin-top: 19px;

  &:focus-visible {
    outline: none;
  }
}

.content {
  width: 562.16px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  padding: 33.7297px;
  // height: 495px;
  border-radius: 17px;
}

.radioCheckbox {
  input {
    box-shadow: none !important;
  }
  input:checked {
    background-color: white !important;
    border-color: $PRIMARY;
    border: 2px solid $PRIMARY;
  }

  input:checked[type="radio"] {
    background-image: url(./../../../assets/icons/dot.svg);
    background-size: 50px;
  }
}

.cancelButton {
  width: 100%;
  height: 44px;
  border-radius: 28px;
  font-family: HNRegular;
  background: #ffffff;
  border: 1.40541px solid $BORDER;
  box-shadow: 0px 1.40541px 2.81081px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  color: #344054;
  &:hover {
    background: #ffffff;
    border: 1.40541px solid $BORDER;
    color: #344054;
  }
}

.reportButton {
  width: 100%;
  height: 44px;
  border-radius: 28px;
  font-family: HNRegular;
  background: #f31f28;
  border: 1.40541px solid #f31f28;
  box-shadow: 0px 1.40541px 2.81081px rgba(16, 24, 40, 0.05);
  color: #ffffff;

  &:hover {
    background: #f31f28;
    border: 1.40541px solid #f31f28;
  }
}

@media screen and (max-width: 620px) {
  @mixin dialogContent {
    overflow-y: auto;
    min-width: auto !important;
  }

  .reportDialog {
    @include dialogContent();
  }
}
