.mentions {
  /* margin: 1em 0; */
  min-height: 44px;
  width: 100%;
}

.mentions--multiLine .mentions__control {
  min-height: 44px;
  width: 100%;
}
.mentions--multiLine .mentions__highlighter {
  height: 100%;
  padding: 9px 14px !important;
  border: 1px solid transparent !important;
  margin: 0px !important;
}
.mentions--multiLine .mentions__input {
  height: 100%;
  padding: 9px 14px !important;
  border: 1px solid #d0d5dd !important;
  outline: 0px !important;
  border-radius: 8px;
  font-family: InterRegular;
  font-size: 16px;
  margin: 0px !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #fff8eb;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #deac00;
  pointer-events: none;
}
