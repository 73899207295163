@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
@import "shared/components/storyCard/style.module.scss";

@mixin dialogContent {
  overflow-y: auto;
  min-width: fit-content;
}

.deleteDialog {
  @include dialogContent();
}

.modalBody {
  padding: 0px;
}

.labelText {
  font-family: ALBold;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #0f1106;
  margin-top: 28px;
  margin-bottom: 45px;
}

.column {
  padding: 5px !important;
}

.deleteButton {
  height: 62px;
  width: 100%;
  background: #d92d20;
  border: 1.40541px solid #d92d20;
  box-shadow: 0px 1.40541px 2.81081px rgba(16, 24, 40, 0.05);
  border-radius: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background: #d92d20;
    border: 1.40541px solid #d92d20;
  }

  &:active {
    background: #d92d20 !important;
    border: 1.40541px solid #d92d20 !important;
  }
}

.cancelButton {
  height: 62px;
  background: #ffffff;
  width: 100%;
  margin-right: 16px;

  border: 1.40541px solid #d0d5dd;
  box-shadow: 0px 1.40541px 2.81081px rgba(16, 24, 40, 0.05);
  border-radius: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #344054;

  &:hover {
    background: #ffffff;
    border: 1.40541px solid #d0d5dd;
    color: #344054;
  }
}

.content {
  width: 562.16px;
  padding: 33px;
  border: 1px solid #e4e4e4;
}

@media screen and (max-width: 620px) {
  @mixin dialogContent {
    overflow-y: auto;
    min-width: auto !important;
  }

  .deleteDialog {
    @include dialogContent();
  }
}
