@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.notificationContainer {
  background-color: white;
  position: absolute;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  right: 0px;
  width: 450px;
  top: 30px;
  z-index: 2;
  max-height: 400px;
  overflow: hidden;
  padding: 24px !important;
}

.post {
  color: $PRIMARY;
  font-weight: 400;
  cursor: pointer;
}

.title {
  font-family: ALBold;
  font-size: 20px;
  color: $DARK_GREY;
  text-align: left;
}

.highlightedLabel {
  font-family: HNMedium;
  font-size: 14px;
  color: $PRIMARY;
  text-align: left;
}

.counterContainer {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background-color: #fdf8ea;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countLabel {
  font-family: InterMedium;
  font-size: 12px;
  color: $PRIMARY;
}

.avatar {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
  border: 3px solid white;
}

.succIcon {
  height: 48px;
  width: 48px;
  z-index: 1;
}

.verticalLine {
  width: 2px; /* Adjust the width as needed */
  background-color: #000; /* Adjust the color as needed */
  margin-left: 12px; /* Adjust the margin as needed */
  height: auto; /* Adjust the height as needed */
}

.userTitle {
  font-family: HNMedium;
  font-size: 14px;
  color: $DARK_GREY;
  text-align: left;
  @include elipsis(1);
}

.time {
  font-family: HNRegular;
  font-size: 12px;
  color: $GREY;
  text-align: left;
  @include elipsis(1);
}

.notifyLabel {
  font-family: HNRegular;
  font-size: 14px;
  color: $GREY;
  text-align: left;
}

.notifyLabelHighlighted {
  font-family: HNRegular;
  font-size: 14px;
  color: $PRIMARY;
  text-align: left;
}

.listContainter {
  max-height: 350px;
  overflow-y: auto;
}

.listContainter::-webkit-scrollbar {
  width: 6px;
}

.listContainter::-webkit-scrollbar-thumb {
  background-color: $PRIMARY;
  border-radius: 100px;
}

.listContainter::-webkit-scrollbar-track {
  border: 1px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  background-color: #f1f3f6;
}

.notifyCardContainer {
  position: absolute;
  width: 2px;
  background: #e4e7ec;
  top: 0px;
  height: 100%;
  left: 23px;
}

.greenDot {
  position: absolute;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 10px;
  background: $PRIMARY;
  border-radius: 100%;
}

.noContentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noContentIcon {
  width: 106.36px;
  height: 94.9px;
}

.noContentLabel {
  font-size: 14px;
}

@media screen and (max-width: 393px) {
  .notificationContainer {
    right: -120px;
    width: 330px;
  }
}

@media (min-width: 393px) and (max-width: 491px) {
  .notificationContainer {
    right: -100px;
    width: 330px;
  }
}

@media (min-width: 491px) and (max-width: 567px) {
  .notificationContainer {
    right: 0px;
    width: 330px;
  }
}

@media (min-width: 567px) and (max-width: 767px) {
  .notificationContainer {
    right: -270px;
    width: 330px;
  }
}
