@import "shared/utils/colors.scss";

.title {
  font-size: 24px;
  font-family: ALBold;
  color: $DARK_GREY;
  text-align: left;
  align-self: flex-start;
}
.subTitle {
  font-size: 14px;
  font-family: HNRegular;
  color: $GREY;
  text-align: left;
  align-self: flex-start;
}

.subTitleLoader {
  height: 14px;
}

.iconStyle {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
}
.iconStyle path {
  stroke-width: 1.5px;
}

.notContent {
  height: 160px !important;
  width: 160px !important;
}

.noContentLabel {
  font-family: InterMedium !important;
  font-size: 14px !important;
  color: $DARK_GREY !important;
}

.btmBorder {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  background-color: #dee2e6;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 20px;
  }
  .subTitle {
    font-size: 12px;
  }
}
