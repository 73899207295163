@import "shared/utils/colors.scss";
.socialIconContainer {
  border: 1px solid $BORDER;
  border-radius: 24px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
}
.socialText {
  font-size: 16px;
  font-family: "HNRegular";
  color: $DARK_GREY;
}

.twContainer {
  border: 0px !important;
  background-color: transparent !important;
  min-height: 44px;
  width: 100%;
}
