@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.toplevelContainer {
  height: 372px;
  position: relative;
  background-image: url("../../../assets/images/header-section.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 372px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin contentContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contentContainer {
  @include contentContainer();
  max-width: 720px;
}

.contentContainer2 {
  @include contentContainer();
  max-width: 820px;
}

.timeLabel {
  font-family: HNBold;
  font-size: 16px;
  color: $PRIMARY;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.timeLabelLoader {
  height: 24px;
  width: 180px;
}

.titleLabel {
  font-family: ALBold;
  font-size: 58px;
  color: $DARK_GREY;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 50px;
}

.subtitleLabel {
  font-family: HNRegular;
  font-size: 20px;
  color: $GREY;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-top: 20px;
}

.searchContainer {
  height: 48px;
  width: 320px;
  border-radius: 24px;
  border: 1px solid $LIGHT_GREY;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.iconStyle {
  height: 20px;
  width: 20px;
}

.iconStyle path {
  stroke: $GREY;
}

.searchInput {
  border: 0;
  background-color: transparent;
  outline: 0;
  width: 90%;
}

.btnContainer {
  width: 153px;
}

@media (min-width: 300px) and (max-width: 992px) {
  .timeLabel {
    font-size: 14px;
  }

  .titleLabel {
    font-size: 50px;
  }

  .subtitleLabel {
    font-size: 18px;
  }
}
