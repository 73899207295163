@import "shared/utils/colors.scss";

.inputContainer {
  background-color: transparent;
  min-height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid $BORDER;
  background-color: white;
}

.inputStyle {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0px;
  outline: 0;
  font-family: HNRegular;
  font-size: 16px;
}

.inputStyle::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: HNRegular;
  color: $GREY2;
  opacity: 1; /* Firefox */
}

.inputStyle:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: HNRegular;
  color: $GREY2;
}

.inputStyle::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: HNRegular;
  color: $GREY2;
}

.error {
  color: #ea5b5b;
  font-size: 11px;
  text-align: left;
  // position: absolute;
  z-index: 1;
}

.maxLabel {
  font-family: InterRegular;
  color: $GREY;
  font-size: 12px;
  text-align: left;
  z-index: 1;
}
