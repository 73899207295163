@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.artistsContainer {
  min-width: 96px;
  width: 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.title {
  @include elipsis(1);
  font-size: 18px;
  color: $DARK_GREY;
}

.subTitle {
  font-size: 14px;
  color: #808080;
}

.artistsStyle {
  min-width: 227px;
  object-fit: cover;
  height: 246.32px;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .artistsContainer {
    min-width: 96px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .artistsStyle {
    min-width: 100%;
    height: 150px;
  }
}
@media screen and (min-width: 576px) {
  .artistsContainer {
    min-width: 96px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .artistsContainer {
    min-width: 96px;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .artistsContainer {
    min-width: 96px;
    width: 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media screen and (min-width: 1200px) {
  .artistsContainer {
    min-width: 96px;
    width: 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 1400px) {
  .artistsContainer {
    min-width: 96px;
    width: 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
