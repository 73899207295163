@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.contributeLabel {
  font-family: ALBold;
  font-size: 24px;
  text-align: left;
  color: $DARK_GREY;
}

.topLevelContainer {
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topLevelContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topLevelContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin tagContainer {
  border-radius: 54px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

.activeTag {
  @include tagContainer();
  background-color: $SECONDARY;
  border: 1px solid $SECONDARY;
}

.inActiveTag {
  @include tagContainer();
  background-color: white;
  border: 1px solid $GREY2;
}

@mixin tagLabel {
  font-family: HNMedium;
  font-size: 12px;
}

.activeLabel {
  @include tagLabel();
  color: black;
}

.inActiveLabel {
  @include tagLabel();
  color: $GREY2;
}
